<template lang="pug">
include ../../../configs/template.pug
div.row
  div(v-if="checkAccess('qualificationStatement', 'viewPayment', statement)").col-12
    +select('body.is_payed', 'paymentStatus', 'payment', 'nameLang')(item-value="status")
  div(v-if="checkAccess('qualificationStatement', 'viewStatus', statement)").col-12
    +select('body.status_document', 'listStatuses', 'status', 'nameLang')
  div(v-if="checkAccess('qualificationStatement', 'viewStatus', statement) && body.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.REJECTED").col-12
    Reject
  FileDropZone(v-if="checkAccess('qualificationStatement', 'maradVerification', statement) && body.status_document !== STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.REJECTED" ref="mediaContent").col-12
  div(v-if="(statement.status_dkk.have_all_docs || statement.is_payed || checkAccess('qualificationStatement', 'maradVerification', statement)) && body.status_document !== STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.REJECTED").col-12.mt-3
    v-btn(
      @click="changeQualificationStatementStatus"
      :loading="isLoading"
      color="success")
      span(v-if="checkAccess('admin')") {{ $t('save') }}
      span(v-else) {{ checkAccess('qualificationStatement', 'maradVerification', statement) ? $t('setVerify') : $t('save') }}
</template>

<script>

import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { STATUSES_STATEMENT_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

const initBody = () => ({
  status_document: null,
  is_payed: null
})
export default {
  name: 'SailorQualificationStatementEditStatus',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue'),
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      body: initBody(),
      isLoading: false,
      payment: null,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['statusChoose', 'paymentStatusByStatus']),
    ...mapState({
      id: state => state.sailor.sailorId,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      paymentStatus: state => state.directory.paymentStatus
    }),
    listStatuses () {
      return this.statusChoose('StatementDKK&Qual')
    }
  },
  mounted () {
    Object.keys(this.body).forEach(key => (this.body[key] = this.statement[key]))
  },
  methods: {
    ...mapActions(['updateQualificationStatement', 'getQualificationStatements']),

    async changeQualificationStatementStatus () {
      if (!this.body.status_document || !this.body.is_payed) {
        this.$notification.info('notify.info.forChangedStatusStatementPaiedIt')
        return false
      }
      this.isLoading = true
      let data = {
        ...this.$route.params,
        body: { ...this.body },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_qualification_document'
        }
      }

      const response = await this.updateQualificationStatement(data)
      if (SUCCESS_CODE.includes(response?.code)) {
        this.$notification.success('editedQualificationStatement')
      }
      this.isLoading = false
    }
  }
}
</script>
